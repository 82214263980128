<template lang="pug">
.kyc-custom-field.animated.fadeIn
  v-loading(:loader = 'loading')
    .text-center(slot = 'spinner'): i.fa.fa-spinner.fa-pulse.fa-lg.m-4
    form.form-horizontal.animated.fadeIn(
      novalidate
      @submit.prevent = 'onSubmit'
    )
      .card
        .card-header {{ header }}
        .card-body
          .row
            .col-lg-6
              fi-form-field(:label = '$t("order")')
                input.form-control.form-control-sm(v-model.number="customFieldData.fieldOrder")
              fi-form-field(:label = '$t("customField")')
                fi-select(
                  v-model = "customFieldData.customFieldId"
                  :options = "customFieldOptions"
                  required
                )
              fi-form-field(:label = '$t("customerSector")')
                fi-select(
                  v-model = "customFieldData.customerSector"
                  :options = "optionsFromClassifier('sectors')"
                  required
                )
              fi-form-field(:label = '$t("required")')
                fi-switch(v-model   = 'customFieldData.required')
              fi-form-field(:label = '$t("enabled")')
                fi-switch(v-model   = 'customFieldData.enabled')
              //- fi-form-field(:label = '$t("enabledApplication")')
              //-   fi-switch(v-model   = 'customFieldData.enabledApplication')
              fi-form-field(:label="$t('enabledPortal')")
                fi-switch(v-model   = 'customFieldData.enabledPortal')
          .row
            .col-lg
              button.btn.btn-primary.mb-3(
                type      = 'submit'
                :disabled = 'saving'
              ) {{ $t('common:save') }}
                i.fa.fa-spinner.fa-pulse.ml-1(v-if = 'saving')
              | &nbsp;
              button.btn.btn-secondary.mb-3(
                :disabled      = 'saving'
                @click.prevent = 'onCancel'
              ) {{ $t('common:cancel') }}
</template>


<script>
import FiFormField from '@/components/FiFormField'
import FiSelect from '@/components/FiSelect'
import FiSwitch from '@/components/FiSwitch'
import { mapActions, mapGetters, mapState } from 'vuex'
import pick from 'lodash/pick'

export default {
  name: 'kyc-custom-field',

  components: { FiSwitch, FiSelect, FiFormField },

  props: {
    id: {
      type: [Number, String],
      default: ''
    }
  },

  i18nOptions: {},

  data: () => ({
    customFieldData: {
      customFieldId: null,
      customerSector: null,
      enabled: false,
      required: false,
      // enabledApplication: false,
      enabledPortal: false,
      fieldOrder: null
    }
  }),

  computed: {
    ...mapState('auth', ['user']),
    ...mapState('customFields', ['customFields', 'KYCCustomField']),
    ...mapGetters('classifiers', ['optionsFromClassifier']),
    customFieldOptions () {
      return this.customFields.map(({ id, name }) => ({ value: id, text: name }))
    },
    isNew () {
      return !this.id
    },
    header () {
      if (this.isNew) {
        return this.$t('newTitle')
      } else {
        return this.$t('editTitle', { id: this.id })
      }
    },
    loading () {
      return [`customer:field:${this.id}:fetch`]
    },
    saving () {
      return this.$vueLoading.isLoading(this.isNew ? 'custom:field:save' : `custom:field:${this.id}:save`)
    }
  },

  watch: {
    id: {
      async handler (id) {
        await this.loadKYCCustomField({ id })
        this.customFieldData = {
          ...this.customFieldData,
          ...pick(this.KYCCustomField, Object.keys(this.customFieldData))
        }
      },
      immediate: true
    }
  },

  created () {
    this.loadCustomFields()
  },

  methods: {
    ...mapActions('customFields', ['loadCustomFields']),
    ...mapActions('customFields', ['saveKYCCustomField', 'loadKYCCustomField', 'updateKYCCustomField']),
    async onSubmit () {
      const { id, customFieldData } = this
      if (this.isNew) {
        await this.saveKYCCustomField({ customFieldData })
      } else {
        await this.updateKYCCustomField({ customFieldData, id })
      }
      this.$router.push({ name: 'KYCCustomFieldList' })
    },
    onCancel () {
      this.$router.push({ name: 'KYCCustomFieldList' })
    }
  }
}
</script>


<i18n>
en:
  newTitle:           "New KYC field"
  editTitle:          "Edit KYC field #{{ id }}"
  order:              "Order"
  customField:        "Custom field"
  customerSector:     "Customer sector"
  enabled:            "Enabled"
  enabledApplication: "Application"
  enabledPortal:      "Portal"
  required:           "Required"
et:
  newTitle:           "New KYC field"
  editTitle:          "Edit KYC field #{{ id }}"
  order:              "Order"
  customField:        "Custom field"
  customerSector:     "Customer sector"
  enabled:            "Enabled"
  enabledApplication: "Application"
  enabledPortal:      "Portal"
  required:           "Required"
ru:
  newTitle:           "New KYC field"
  editTitle:          "Edit KYC field #{{ id }}"
  order:              "Order"
  customField:        "Custom field"
  customerSector:     "Customer sector"
  enabled:            "Enabled"
  enabledApplication: "Application"
  enabledPortal:      "Portal"
  required:           "Required"
</i18n>
